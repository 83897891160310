import React from 'react';

const RedirectPage = () => {
  React.useEffect(() => {
    window.location.replace('https://yourparking.hu')
  }, [])

  return null;
};

export default RedirectPage;