import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const useQuery = () => {
  const { search } = useLocation();
  console.log(search);

  return React.useMemo(() => queryString.parse(search), [search]);
}

export default useQuery;
