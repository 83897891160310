/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import RevolutCheckout from '@revolut/checkout';
import { useMutationObserver } from "rooks";
import axios from 'axios';

import { URLS } from './constants';
import useQuery from './hooks/useQuery';

import './App.css';
import './styles/loader.css'

const instance = axios.create({
  baseURL: process.env.REACT_APP_AWS_API_ENDPOINT,
});


const isAndroid = /(android)/i.test(navigator.userAgent);

const Checkout = () => {
  const environment = window.location.host.includes('dev') || window.location.host.includes('localhost') || window.location.host.includes('192.168.0.') ? 'sandbox' : 'prod';
  let query = useQuery();

  const [state, setState] = useState<{
    name: string;
    email: string;
  }>({
    name: (query?.fullName as string) || '',
    email: (query?.email as string) || '',
  });

  const [order, setOrder] = useState<null | { currency: string; amount: number; description: string; }>(null);
  const [loading, setLoading] = useState(true);
  const [fullFilled, setFullFilled] = useState(false);

  const cardRef = useRef(null);

  const handleMutations = (mutations: MutationRecord[]) => {
    if (!mutations) return;
    for (const { target, attributeName } of mutations) {
      if (attributeName === "style") {
        const textStyle: string = (target as any).style.cssText;
        if (textStyle.includes("height: 1.5em")) {
          (target as any).style.cssText = textStyle.replace("height: 1.5em", "height: 50px")
        }
      }
    }
  }

  useMutationObserver(cardRef, handleMutations);
  const fetchCheckout = async () => {

    try {
      const { data } = await axios.get(`${URLS[environment]}/orders/token/${query.token}`);
      setOrder(data);
      const RC = await RevolutCheckout(query?.token as string, environment);
      const cardField = document.getElementById("card-field") as HTMLElement;

      const card = RC.createCardField({
        target: cardField,
        hidePostcodeField: true,
        styles: {
          default: {
            border: 'none',
            borderRadius: '6px',
            height: '50px',
            outline: 'none',
            backgroundColor: '#f2f2f2',
            fontSize: '14px',
            padding: '10px 10px'
          }
        },
        onSuccess() {
          setFullFilled(true);
          setLoading(true);
          if (isAndroid) {
            window.location.replace("yourparking://")
          }
        },
        onError(message) {
          setFullFilled(true);
          setLoading(true);
          if (isAndroid) {
            window.location.replace("yourparking://")
          }
        },
      });



      if (environment === "prod") {

        const { data: billingData } = await instance.get(`/revolut/${query.token}/billing-address`);
        console.log(billingData)

        const paymentRequest = RC.paymentRequest({
          target: document.getElementById('revolut-payment-request') as HTMLElement,
          requestShipping: false,
          onSuccess() {
            setFullFilled(true);
            setLoading(true);
            if (isAndroid) {
              window.location.replace("yourparking://")
            }
          },
          onError(message) {
            setFullFilled(true);
            setLoading(true);
            if (isAndroid) {
              window.location.replace("yourparking://")
            }
          },
          /*       billingAddress: {
                  name: state.name,
                  email: state.email,
                  billingAddress: {
                    countryCode: billingData.country,
                    postcode: billingData.zip,
                    region: billingData.city,
                    city: billingData.city,
                    streetLine1: billingData.streetName,
                    streetLine2: billingData.houseNumber,
                  },
                }, */
        });

        paymentRequest.canMakePayment().then(
          (result) => {
            if (result) {
              paymentRequest.render()
            } else {
              // paymentRequest.destroy()
            }
          });

      }
      setLoading(false);

      const form = document.querySelector("form");
      form?.addEventListener("submit", function (event) {
        // Prevent browser form submission. You need to submit card details first.
        event.preventDefault();

        const data = new FormData(form as HTMLFormElement);
        card.submit({
          name: data.get("full_name") as string,
          email: data.get("email") as string,
        });
      });
    } catch (error) {
      alert(error)
      // window.location.replace('https://yourparking.hu')
      setLoading(false);
    }

  };

  useEffect(() => {
    fetchCheckout();
  }, []);

  if (!order) return null;

  return (
    <>
      {loading &&
        <div className="loading">Loading</div>
      }
      {!fullFilled && (
        <div className='content'>
          <div className='logoContainer'>
            <img src='/img/logo-light.png' alt="logo" className='logo' />
          </div>
          <div>
            <div className='textContainer'>
              <h2>Összeg:</h2>
              <h2>{new Intl.NumberFormat('hu-HU', { style: 'currency', currency: order?.currency?.toLowerCase() || 'huf' }).format((order?.amount || 0) / 100)}</h2>
              <p>{order?.description}</p>
            </div>
            <div>
            </div>
          </div>
          <form className="formContainer" id="form">
            <div className="form_fields">
              <div className="input_group">
                <div className="input_container">
                  <input type="text" id="full_name" name="full_name" placeholder="Név" required value={state.name} onChange={(evt) => setState({ ...state, name: evt?.target.value || '' })} />
                </div>
                <div className="input_container">
                  <input type="email" id="email" name="email" placeholder="Email" value={state.email} onChange={(evt) => setState({ ...state, email: evt?.target.value || '' })} />
                </div>
              </div>
              <div ref={cardRef as any} id="card-field"></div>
            </div>
            <div className='buttonContainer'>
              <input className='submitSpan' value="Fizetés" type="submit" />
            </div>
            <div id='revolut-payment-request' />
            <div className="poweredByContainer">
              <p>Powered by:</p>
              <img src='https://assets.revolut.com/media/business/payment-link/revolut-logo.svg' alt="revolut business" />
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default Checkout;
