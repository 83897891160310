import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Checkout from './checkout';
import Redirect from './redirect';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as any);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/checkout" element={<Checkout />} />
        <Route
          path="*"
          element={<Redirect />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);